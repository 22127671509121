import React, { useContext, useState } from 'react'
import '../student/user.css'
import { TeachersActions, Alert } from '../../Components/reusables'
import {SideMenu} from '../../Components/sideMenu'
import Header from '../../Components/header'
import axios from 'axios'
import { UserInterfaceContext } from '../../Contexts/ui-context'

function AddTeacher() {
  const {Progress, AlertNotice } = useContext(UserInterfaceContext)
  const [firstName,setFirstName]= useState('')
  const [middleName,setMiddleName]= useState('')
  const [lastName,setLastName]= useState('')
  // const [classValue,setClassValue]= useState()
  // const [subject,setSubject]= useState('')
  const [staffCategory,setStaffCategory]= useState('')
  // const [isSubjectTeacher, setIsSubjectTeacher] = useState('')
  const [gender,setGender]= useState('')
  const [dob,setDob]= useState('')
  const [telephone1,setTelephone1]= useState('')
  const [telephone2,setTelephone2]= useState('')
  const [email,setEmail]= useState('')
  const [bankName,setBankName]= useState('')
  const [accountNumber,setAccountNumber]= useState('')
  const [salary,setSalary]= useState('')
  const [tin,setTin]= useState('')
  const [snnitNumber,setSnnitNumber]= useState('')
  const [nationality,setNationality]= useState('')
  const [nationalIdType,setNationalIdType]= useState('')
  const [nationalIdNumber,setNationalIdNumber]= useState('')
  const [address,setAddress]= useState('')
  const [dateOfEmployment,setDateOfEmployment]= useState('')
  const [selectedSubjects, setSelectedSubjects] = useState([]);


const teacherData ={
    firstName : firstName,
    middleName : middleName,
    lastName : lastName,
    gender : gender,
    dob :dob ,
    telephone1 : telephone1,
    telephone2 : telephone2,
    email : email,
    dateOfEmployment : dateOfEmployment,
    subjects : selectedSubjects,
    staffCategory : staffCategory,
    bankName : bankName,
    accountNumber : accountNumber,
    salary : salary,
    tin : tin , 
    snnitNumber : snnitNumber ,
    nationality : nationality,
    nationalIdType : nationalIdType , 
    nationalIdNumber : nationalIdNumber ,
    address : address ,

}
function ResetInputs (){
    setFirstName('') 
    setMiddleName('')
    setLastName('')
    setGender('')
    setDob('') 
    setTelephone1('')
    setTelephone2('')
    setEmail('') 
    setDateOfEmployment('')
    setSelectedSubjects([])
    setStaffCategory('')
    setBankName('')
    setAccountNumber('')
    setSalary('')
    setTin('')
    setSnnitNumber('') 
    setNationality('') 
    setNationalIdType('')
    setNationalIdNumber('')
    setAddress('')
}
const filteredTeachersData = Object.entries(teacherData).reduce((acc, [key, value]) => {
  if (value !== null && value !== '') {
    acc[key] = value;
  }
  return acc;
}, {});
function handleTeacherSubmit (e){
  e.preventDefault()
    Progress('30%')
    // axios.post ('https://new-softesc-backend.onrender.com/teachers', teacherData)
    axios.post ('https://new-softesc-backend.onrender.com/staff', filteredTeachersData)
    .then((response)=> {
        AlertNotice(response.data.message, true)
        Progress('100%')
        //console.log('teacher submitted')
        ResetInputs()
    })
    .catch((error)=> {
        AlertNotice(error.response.data.error, false)
        Progress('0%')  
        //console.log('error submitting teacher', error)
    })
    
}


const subjects = [
  ["HEALTH -PS", "OWOP -PS", "MATHS -PS", "LANG & COMM -PS", "SENSES -PS", "PHYSICAL REPORT -PS", "CREATIVE-ARTS-PS", "COGNITIVE ABILITY -PS"],
  ["Numeracy -LP", "Literacy  -LP", "Science  -LP", "OWOP  -LP", "HISTORY  -LP", "RME  -LP", "COMPUTING  -LP", "CREATING ARTS  -LP", "FRENCH  -LP", "GH.LANG (TWI)  -LP"],
  ["Numeracy -UP", "Literacy  -UP", "Science  -UP", "OWOP  -UP", "HISTORY  -UP", "RME  -UP", "COMPUTING  -UP", "CREATING ARTS  -UP", "FRENCH  -UP", "GH.LANG (TWI)  -UP"],
  ["Numeracy -JHS", "Literacy  -JHS", "Science  -JHS", "Creative Tech  -JHS", "OWOP  -JHS", "HISTORY  -JHS", "RME  -JHS", "COMPUTING  -JHS", "CREATING ARTS  -JHS", "FRENCH  -JHS", "GH.LANG (TWI)  -JHS"]
];

  // Function to handle checkbox change
  const handleSelectedSubjectCheckBox = (e) => {
      const { value, checked } = e.target;
      if (checked) {
          // Add the subject to the selectedSubjects array
          setSelectedSubjects([...selectedSubjects, value]);
      } else {
          // Remove the subject from the selectedSubjects array
          setSelectedSubjects(selectedSubjects.filter(subject => subject !== value));
      }
  };


const [selectedLevels, setSelectedLevels] = useState([]);
const Levels = ['PRE SCHOOL', 'LOWER PRIMARY', 'UPPER PRIMARY', 'JUNIOR HIGH SCHOOL'];
  // Function to handle checkbox change
  const handleSelectedLevelCheckBoxs = (e) => {
      const { value, checked } = e.target;
      if (checked) {
          // Add the subject to the selectedSubjects array
          setSelectedLevels([...selectedLevels, value]);
      } else {
          // Remove the subject from the selectedSubjects array
          setSelectedLevels(selectedLevels.filter(Levels => Levels !== value));
      }
  };


  return (<div className="App">
  <SideMenu/>
  <Alert/>
  <div className="main-menu">
    <Header/>
    <div className='add-teacher content-loader'>
      <TeachersActions/>
      <form action='' onSubmit={handleTeacherSubmit}>
        <br />
      <h2 id='blue'>Staff Information</h2>
      <div className="teacher-info sorting">
         <div className="sorting-body">
         <div className='input'>
          <label htmlFor="">First Name: </label>
          <input 
            type="text" 
            required
            placeholder='eg. John'
            value={firstName}
            onChange={(e)=>{setFirstName(e.target.value)}}
            /> 
          </div>

          <div className='input'>
          <label htmlFor="">Middle Name: </label>
          <input 
            type="text" 
            placeholder='eg . Kofi'
            value={middleName}
            onChange={(e)=>{setMiddleName(e.target.value)}}
            /> 
          </div>

          <div className='input'>
          <label htmlFor="">Last Name:  </label>
          <input 
            type="text" 
            placeholder='Doe'
            required
            value={lastName}
            onChange={(e)=>{setLastName(e.target.value)}}
            /> 
          </div>

          <div className='input'>
          <label htmlFor="">Gender: </label>
          <select 
            id="mySelect" 
            required
            value={gender}
            onChange={(e)=>{setGender(e.target.value)}}
          >
                <option value="" disabled hidden>Choose gender </option>
                <option value="male" >Male</option>
                <option value="female">Female</option>
            </select>          </div>

          <div className='input'>
          <label htmlFor="">Date of Birth: </label>
          <input 
            type="date" 
            required
            value={dob}
            onChange={(e)=>{setDob(e.target.value)}}
            /> 
          </div>

          <div className='input'>
          <label htmlFor="">Telephone : </label>
          <input 
            type="text" 
            required
            placeholder='eg. 055992234. must be valid'
            value={telephone1}
            onChange={(e)=>{setTelephone1(e.target.value)}}
            /> 
          </div>
          <div className='input'>
          <label htmlFor="">Telephone : </label>
          <input 
            type="text" 
            required
            placeholder='eg. 055992234. must be valid'
            value={telephone2}
            onChange={(e)=>{setTelephone2(e.target.value)}}
            /> 
          </div>

          <div className='input'>
          <label htmlFor="">Email: </label>
          <input 
            type="email" 
            required
            value={email}
            onChange={(e)=>{setEmail(e.target.value)}}
            /> 
          </div>  
          
          <div className='input'>
          <label htmlFor="">Date of Employment: </label>
          <input 
            type="date" 
            required
            value={dateOfEmployment}
            onChange={(e)=>{setDateOfEmployment(e.target.value)}}
            /> 
          </div>
         </div>

          
      </div>


      <h2 id='blue'>School Details</h2>
      <div className="sch-details sorting">
          <div className="sorting-body">
          <div className='input'>
          <label htmlFor="">Staff Category: </label>
          <select 
            id="mySelect" 
            value={staffCategory}
            onChange={(e)=>{setStaffCategory(e.target.value)}}
            >
                <option value="" hidden disabled> Select a  staff category </option>
                <option value="owner"> Owner </option>
                <option value="administration">Administration</option>
                <option value="teaching">Teaching</option>
                <option value="kitchen ">Kitchen</option>
                <option value="security">Security</option>
                <option value="library">Library</option>
                <option value="others">Others</option>
            </select>          </div>

            {/* {
              staffCategory === 'teaching'?
              <div className="input">
                  <label htmlFor="">SubjectTeacher: </label>
                            <div style={{display : 'flex', marginLeft : '1rem',gap :'2rem'}}>
                                <div style={{display : 'flex', alignItems : 'center', gap : '0.5rem'}}>
                                    <input 
                                        type="radio" 
                                        name="isSubjectTeacher" 
                                        value = 'true'
                                        id="option1" 
                                        onChange={(e)=>{setIsSubjectTeacher(e.target.value)}}
                                        />
                                    <label for="option1" > Yes </label>
                                </div>

                                <div style={{display : 'flex', alignItems : 'center', gap : '0.5rem'}}>
                                    <input 
                                        type="radio" 
                                        name="isSubjectTeacher" 
                                        value= 'false' 
                                        id="option2"
                                        onChange={(e)=>{setIsSubjectTeacher(e.target.value)}}

                                        />
                                    <label for="option2">No  </label>
                                </div>
                            </div>
              </div>:null
            } */}
            
            {/* {
            staffCategory==='teaching'? <div className='input' style={{minWidth : '80%'}}>
            <label htmlFor="">Assign Class: </label>
            <select 
              id="mySelect" 
              required
              value={classValue}
              onChange={(e)=>{setClassValue(e.target.value)}}
              >
                  <option value="" disabled hidden> Choose class </option>
                  <option value={-3}>Preschool - Nursery 1</option>
                  <option value={-2}>Preschool - Nursery 2</option>
                  <option value={-1}>Preschool - Kingdergarten 1</option>
                  <option value={0}>Preschool - Kingdergarten 2</option>
                  <option value={1}>Class 1</option>
                  <option value={2}>Class 2</option>
                  <option value={3}>Class 3</option>
                  <option value={4}>Class 4</option>
                  <option value={5}>Class 5</option>
                  <option value={6}>Class 6</option>
                  <option value={7}>Class 7 / JHS 1</option>
                  <option value={8}>Class 8 / JHS 2</option>
                  <option value={9}>Class 9 / JHS 3</option>
                  <option value={100} style={{fontWeight : 'bolder', color : 'red'}}> Not Class Teacher </option>

              </select>          
      </div>:null
          } */}
          

            {
              (staffCategory==='teaching')? 
              <div className="input" style={{minWidth: '100%'}}> 
                    {/* <div> */}
                        <div style={{display: 'flex', rowGap : '1rem', columnGap: '4rem', flexWrap : 'wrap', minWidth : '100%' }}>
                        {
                          Levels.map((level, index) => (
                              <div key={index} style={{maxWidth: '40%', minWidth : '14rem', paddingBottom: '1rem'}}>
                              <b>{level}</b>
                              {subjects[index].map((subject, subIndex) => (
                                <div key={subIndex} style={{paddingLeft : '1rem'}}>
                                  <input 
                                    type="checkbox" 
                                    id={`subject-${index}${subIndex}`} 
                                    name={subject} 
                                    value={subject} 
                                    checked={selectedSubjects.includes(subject)} 
                                    onChange={handleSelectedSubjectCheckBox} 
                                  />
                                  <label htmlFor={`subject-${index}${subIndex}`} style={{marginLeft : '0.5rem'}}>{subject}</label>
                                </div>
                              ))}
                            </div>

                          ))
                        }
                        </div>
                    {/* </div> */}
              </div>:null
              
              
            }


          
          </div>
                  
      </div>


      <h2 id='blue'>Financial Details</h2>
      <div className="financial-details sorting">
          <div className="sorting-body">
          <div className='input'>
              <label htmlFor="">Bank Name: </label>
              <input 
                type="text" 
                required
                value={bankName}
                onChange={(e)=>{setBankName(e.target.value)}}
                /> 
          </div>
      
          <div className='input'>
              <label htmlFor="">Account Number : </label>
              <input 
                type="text" 
                required
                value={accountNumber}
            onChange={(e)=>{setAccountNumber(e.target.value)}}
                /> 
          </div>

          <div className='input'>
              <label htmlFor="">Salary: </label>
              <input 
                type="number"
                required 
                value={salary}
                onChange={(e)=>{setSalary(e.target.value)}}
                /> 
          </div>
      
          <div className='input'>
              <label htmlFor="">TIN: </label>
              <input 
                type="number" 
                value={tin}
                onChange={(e)=>{setTin(e.target.value)}}
                /> 
          </div>
      
          <div className='input'>
              <label htmlFor="">SSNIT: </label>
              <input 
                type="number" 
                value={snnitNumber}
                onChange={(e)=>{setSnnitNumber(e.target.value)}}
                /> 
          </div>
          </div>

      </div>


      <h2 id='blue'>Identification and Contacts</h2>  
      <div className="id-contact-details sorting">
          <div className="sorting-body">
          <div className='input'>
          <label htmlFor="">Nationality : </label>
          <input 
            type="text" 
            required
            value={nationality}
            onChange={(e)=>{setNationality(e.target.value)}}
            /> 
          </div>
      <div className='input'>
          <label htmlFor="">National ID: </label>
          <select 
            id="mySelect" 
            required
            value={nationalIdType}
            onChange={(e)=>{setNationalIdType(e.target.value)}}
            >
                <option value="" hidden disabled>Choose a card </option>
                <option value="GHCARD">Ghana Card</option>
                <option value="VOTER">Voter's ID</option>
                <option value="NHI">NHIS</option>
                <option value="DRIVER">Driver's License</option>
            </select>
          </div>

          <div className='input'>
          <label htmlFor="">ID Number: </label>
          <input 
            type="text" 
            required
            placeholder='GHA-XXXX-XXXX-XXXX-X'
            value={nationalIdNumber}
            onChange={(e)=>{setNationalIdNumber(e.target.value)}}
            /> 
          </div>

          <div className='input'>
          <label htmlFor="">Address: </label>
          <input 
            type="text" 
            required
            value={address}
            onChange={(e)=>{setAddress(e.target.value)}}
            /> 
          </div>
          </div>

      </div>


      <div id="submit-reset-buttons">
          <button type='submit'>Submit</button>
          <button>Reset Input</button>
          </div>
      </form>
    </div>
  </div>
</div>
    
  )
}

export default AddTeacher