import React from 'react'
import axios from 'axios'
import SPHeader from './SPCOMPONENTS/sp-header'
import SPSideMenu from './SPCOMPONENTS/sp-sidemenu'
import { useContext,useState, useEffect } from 'react'
import { UserInterfaceContext } from '../Contexts/ui-context'
import { Receive } from 'twilio/lib/twiml/FaxResponse'

function SPMessages() {

  const {setPageName, Progress, AlertNotice} = useContext(UserInterfaceContext)
  setPageName('NOTICES AND MESSAGES')
  const [sortingView, setSortingView] = useState(false)
  function toggleSortingView (){
    setSortingView(!sortingView)
  }

  const [pastMessages, setPastMessages] = useState([])
  const [pastMessageReload, setPastMessageReload] = useState(false)



  useEffect(()=>{
    Progress('30%')
    axios.get('https://new-softesc-backend.onrender.com/notices', {
      params: {
        reciever : 'Parents'
      }
    })
    .then((response)=>{
      Progress('100%')
      setPastMessages(response.data)
    })
    .catch((error)=>{
      Progress('0%')
    })

  },[pastMessageReload])
  

  return (
    <div className='App'>
      <SPSideMenu/>
      <div className='main-menu'>
        <SPHeader/>
        <div  className='content-loader'>
            {/* the main menu content goes here  */}
            <div className="past-messages">
              <h2 className='class-level past-message-header'>MESSAGES </h2>
                <div className="past-messages-body">
                {pastMessages.map((pastMessages, index)=>(
                    <div className="message" key={index}>
                    {/* <h3 id='message-title' style={{color: 'green'}}>Reciever : {pastMessages.reciever}  </h3> */}
                    <h3 id='message-title'>Message title : {pastMessages.title} </h3>
                    <h3 id='message-title' style={{color: 'red'}}>Date Sent : {pastMessages.date} </h3>
                    <p id='message-body'>Message :{pastMessages.messageBody}</p>
                    
                  </div>
                ))}
                </div>
                
                                
              </div>        </div>

      </div>
    </div>
  )
}

export default SPMessages