import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import BPHeader from './TPCOMPONENTS/bp-header'
import BPSideMenu from './TPCOMPONENTS/bp-sidemenu'
import { FinancialActions } from './TPCOMPONENTS/bp-reusables'
import { FaEye,  } from 'react-icons/fa'; // Example icons from Font Awesome
import { IoIosArrowDown } from 'react-icons/io';
import { UserInterfaceContext } from '../Contexts/ui-context'
import { Loading } from '../Components/reusables'
import Receipt from '../Pages/finance/receipt'
import { UserDataContext } from '../Contexts/user-data'
import { Alert } from '../Components/reusables'

function BPAllIncome() {
  const {Progress, AlertNotice, setShowFinancialDeletePallet, setTransanction_id,setTypeofEntry, setLoading, setShowReceipt} = useContext(UserInterfaceContext)
  const {setUserId, setTerm, setYear,setIncome_id  } = useContext(UserDataContext)
  const [academicYear, setAcademicYear] = useState([])
  const [academicTerm, setAcademicTerm] = useState([])
  const [selectedAcademicYear, setSelectedAcademicYear] = useState('')
  const [selectedAcademicTerm, setSelectedAcademicTerm] = useState('')
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [incomeType, setIncomeType] = useState()
  const [incomeData, setIncomeData] = useState([])

  const fetchAcademicYear = async () => {
    try {
      Progress('30%');
      const academicYearResponse = await axios.get('https://new-softesc-backend.onrender.com/academicYear', {
       
      });
      //console.log(academicYearResponse);
      setAcademicYear(academicYearResponse.data);
      Progress('100%');
    } catch (error) {
      console.error(error);
      Progress('0%');
    }
  };

  const fetchAcademicTerm = async () => {
    try {
      //console.log('selected academic year', selectedAcademicYear)
      Progress('70%');
      const academicTermResponse = await axios.get('https://new-softesc-backend.onrender.com/academicTerm',{
        params:{
            academicYearName : selectedAcademicYear===''?'none':selectedAcademicYear,
        }
      });
      //console.log(academicTermResponse);
      setAcademicTerm(academicTermResponse.data);
      Progress('100%');
    } catch (error) {
      console.error(error);
      Progress('0%');
    }
  };
 
 
  const fetchData = async ()  =>{
    setLoading(true)
    Progress('30%')
  axios.get('https://new-softesc-backend.onrender.com/finance/income',{
    params:{
      academicYear : selectedAcademicYear,
      academicTerm : selectedAcademicTerm,
      incomeType : incomeType,
      startDate : startDate,
      endDate: endDate,
    }
  })
  .then((response)=>{
    Progress('100%')
    setIncomeData(response.data)
    setLoading(false)
    //console.log('expenses retrieved successfully')
  })
  .catch((error)=> {
    Progress('0%')
    //console.log(error)
  })
  }


useEffect(()=>{
  fetchData()
  fetchAcademicTerm();
  fetchAcademicYear()

},[selectedAcademicTerm,selectedAcademicYear, incomeType, startDate, endDate])

  const [sortingView, setSortingView] = useState(false)
  function toggleSortingView (){
    setSortingView(!sortingView)
  }


  
  return (
    <div className="App">
        <BPSideMenu/>
        <Alert/>
        <div className="main-menu">
          <BPHeader/>
          {/* <DeleteFinanceEntryPrompt/> */}
          <div className='all-income content-loader'>
        {/* <h3 className='sticky-left'>All Income Information</h3> */}
        <FinancialActions/>
        <div className="sorting sticky-left">
        <div className="sorting-header" onClick={toggleSortingView}>
           <h2>Sort By:</h2>
          <IoIosArrowDown id='sorting-down-arrow'/>
           </div>                
           <div className="sorting-body" style={{display: sortingView?'flex':'none'}}>
           <div className="input">
          <label htmlFor="">Academic Year: </label>
          <select 
            name="" 
            id=""
            value={selectedAcademicYear}
            onChange={(e)=>{setSelectedAcademicYear(e.target.value)}}

            >
            {/* <option value="" disabled hidden >select an academic year</option> */}
            <option value="" >All</option>
            {academicYear.map((year, index)=>(
                  <option key={index} value={year.academicYearName}>{year.academicYearName}</option>

            ))}            

          </select>
          

        </div>
        <div className="input">
          <label htmlFor="">Academic Term: </label>
          <select 
            name="" 
            id=""
            value={selectedAcademicTerm}
            onChange={(e)=>{setSelectedAcademicTerm(e.target.value)}}
            >
            <option value="" disabled hidden>select an academic term</option>
            {academicTerm.map((term, index)=>(
                  <option key={index} value={term.academicTermName}>{term.academicTermName}</option>

            ))}
          </select>
          
          
        </div>
                <div className="input">
                <label htmlFor="">From: </label>
                <input 
                  type="date" 
                  value={startDate}
                  onChange={(e)=>{setStartDate(e.target.value)}}
                  />
                </div>

                <div className="input">
                <label htmlFor="">To: </label>
                <input 
                  type="date" 
                  value={endDate}
                  onChange={(e)=>{setEndDate(e.target.value)}}
                  />
                </div>

                <div className="input">
                <label htmlFor="">Income Type: </label>
                <select 
                  name="" 
                  id=""
                  value={incomeType}
                  onChange={(e)=>{setIncomeType(e.target.value)}}
                  >
                    <option value="">All</option>
                    <option value="schoolFees">School Fees</option>
                    <option value="feedingFees">Feeding Fee</option>
                    <option value="booksAndStationary">Books and Stationery</option>
                    <option value="ClassesFees">Classes Fee</option>
                    <option value="examsFees">Examination Fees</option>
                </select>
                </div>
                </div>
        </div>
        <div className="userfeature">
              <b>Total Fetched : {incomeData.length}</b>
            </div>
        <div className="container">
          <table className='user-table'>
            <Loading/>
              <thead>
                <tr>
                  <th>Ref </th>
                  <th>Income Type</th>
                  <th id='user-name-head'>Paid By </th>
                  <th>Amount (GHS)</th>
                  {/* <th>Phone / Email</th> */}
                  <th>Description</th>
                  <th>Date</th>
                  <th id='user-actions-head'>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  incomeData.map((income, index)=>(
                    <tr key={index}>
                      <td>{income.incomeRefNumber}</td>
                      <td>{income.incomeType}</td>
                      <td id='user-name'>{(income?.refNumber.name)??'p'}</td>
                      <td>{income.amount}</td>
                      {/* <td>{income.telephone}</td> */}
                      <td id='no-wrap'>{income.description}</td>
                      <td>{income.dateAdded}</td>
                      <td id='table-actions'>
                        {/* <FaEye id='action-see'/>  */}
                        {/* <FaEdit/>  */}
                        {
                          income.refNumber.id?(<FaEye 
                            id='action-download' 
                            onClick={()=>{
                              setShowReceipt(true)
                              setTerm(income?.academicTerm)
                              setYear(income?.academicYear)
                              setIncome_id(income?._id)
                              setUserId(income?.refNumber.id)
                            }}
                            />):null
                        }

                        {/* <FaTrash 
                          id='action-delete'
                          onClick={()=>{
                            setShowFinancialDeletePallet(true)
                            setTransanction_id(income._id)
                            setTypeofEntry('income')
                          }}
                          /> */}
                          </td>
                  </tr>
                  ))

                }
               
                
                
              </tbody>
        </table>
      </div>

    </div>
          <Receipt/>

        </div>
              {/* <Receipt/> */}

      </div>
    
  )
}

export default BPAllIncome