import React, { useContext, useState } from 'react'
import '../student/user.css'
import { TeachersActions, Alert } from '../../Components/reusables'
import {SideMenu} from '../../Components/sideMenu'
import Header from '../../Components/header'
import axios from 'axios'
import { UserInterfaceContext } from '../../Contexts/ui-context'
import { UserDataContext } from '../../Contexts/user-data'
import { Navigate } from 'react-router-dom'

function StaffUpdate() {
    const {Progress, AlertNotice } = useContext(UserInterfaceContext)
    const {userId} = useContext(UserDataContext)

  const [firstName,setFirstName]= useState('')
  const [middleName,setMiddleName]= useState('')
  const [lastName,setLastName]= useState('')
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [staffCategory,setStaffCategory]= useState('')
  const [gender,setGender]= useState('')
  const [dob,setDob]= useState('')
  const [telephone,setTelephone]= useState('')
  const [email,setEmail]= useState('')
  const [bankName,setBankName]= useState('')
  const [accountNumber,setAccountNumber]= useState('')
  const [salary,setSalary]= useState('')
  const [tin,setTin]= useState('')
  const [snnitNumber,setSnnitNumber]= useState('')
  const [nationality,setNationality]= useState('')
  const [nationalIdType,setNationalIdType]= useState('')
  const [nationalIdNumber,setNationalIdNumber]= useState('')
  const [address,setAddress]= useState('')
  const [dateOfEmployment,setDateOfEmployment]= useState('')


  if(userId===''){
    return <Navigate to='/AllTeachers'/>
  }

const teacherData ={
  firstName : firstName,
  middleName : middleName,
  lastName : lastName,
  gender : gender,
  dob :dob ,
  telephone : telephone,
  email : email,
  dateOfEmployment : dateOfEmployment,
  staffCategory : staffCategory,
  bankName : bankName,
  accountNumber : accountNumber,
  salary : salary,
  tin : tin , 
  snnitNumber : snnitNumber ,
  nationality : nationality,
  nationalIdType : nationalIdType , 
  nationalIdNumber : nationalIdNumber ,
  address : address ,
}

const filteredTeacherData = Object.entries(teacherData).reduce((acc, [key, value]) => {
  if (value !== null && value !== '') {
    acc[key] = value;
  }
  return acc;
}, {});



function handleTeacherSubmit (e){
    e.preventDefault()
    Progress('30%')
    axios.put(`https://new-softesc-backend.onrender.com/staff/${userId}`, filteredTeacherData)
    .then((response)=>{
        AlertNotice(response.data.message, true)
        Progress('100%')
        ////console.log(response.data.message)
      })
      .catch((error)=>{
        AlertNotice('Failed to Update Staff', false)
        //console.log(error)
        Progress('0%')
      })
}


  return (<div className="App">
  <SideMenu/>
  <Alert/>
  <div className="main-menu">
    <Header/>
    <div className='add-teacher content-loader'>
      <TeachersActions/> <br />
      <form action='' onSubmit={handleTeacherSubmit}>
        <h2 className='class-level'>Update Staff with ID : <b style={{color: 'yellow'}}>{userId}</b></h2>
        <br />
      <h2 id='blue'>Staff Information</h2>
      <div className="teacher-info">
          <div className='input'>
          <label htmlFor="">First Name: </label>
          <input 
            type="text" 
            // required
            placeholder='eg. John'
            value={firstName}
            onChange={(e)=>{setFirstName(e.target.value)}}
            /> 
          </div>

          <div className='input'>
          <label htmlFor="">Middle Name: </label>
          <input 
            type="text" 
            placeholder='eg . Kofi'
            value={middleName}
            onChange={(e)=>{setMiddleName(e.target.value)}}
            /> 
          </div>

          <div className='input'>
          <label htmlFor="">Last Name:  </label>
          <input 
            type="text" 
            placeholder='Doe'
            // required
            value={lastName}
            onChange={(e)=>{setLastName(e.target.value)}}
            /> 
          </div>

          
          <div className='input'>
          <label htmlFor="">Gender: </label>
          <select 
            id="mySelect" 
            // required
            value={gender}
            onChange={(e)=>{setGender(e.target.value)}}
          >
                <option value="" disabled hidden>Choose gender </option>
                <option value="male" >Male</option>
                <option value="female">Female</option>
            </select>          </div>

          <div className='input'>
          <label htmlFor="">Date of Birth: </label>
          <input 
            type="date" 
            // required
            value={dob}
            onChange={(e)=>{setDob(e.target.value)}}
            /> 
          </div>

          <div className='input'>
          <label htmlFor="">Telephone : </label>
          <input 
            type="text" 
            // required
            placeholder='eg. 055992234. must be valid'
            value={telephone}
            onChange={(e)=>{setTelephone(e.target.value)}}
            /> 
          </div>

          <div className='input'>
          <label htmlFor="">Email: </label>
          <input 
            type="email" 
            // required
            value={email}
            onChange={(e)=>{setEmail(e.target.value)}}
            /> 
          </div>

          

          
          <div className='input'>
          <label htmlFor="">Date of Employment: </label>
          <input 
            type="date" 
            // required
            value={dateOfEmployment}
            onChange={(e)=>{setDateOfEmployment(e.target.value)}}
            /> 
          </div>

          
      </div>
      <h2 id='blue'>School Details</h2>
      <div className="sch-details">
      <div className='input'>
          <label htmlFor="">Staff Category: </label>
          <select 
            id="mySelect" 
            value={staffCategory}
            onChange={(e)=>{setStaffCategory(e.target.value)}}
            >
                <option value="" hidden disabled> Select a  staff category </option>
                <option value="Owner"> Owner </option>
                {/* <option value="administration">Administration</option> */}
                <option value="teaching">Teaching</option>
                <option value="kitchen ">Kitchen</option>
                <option value="security">Security</option>
                <option value="library">Library</option>
                <option value="others">Others</option>
            </select>          
            </div>

      </div>

      <h2 id='blue'>Financial Details</h2>
      <div className="financial-details">
          <div className='input'>
              <label htmlFor="">Bank Name: </label>
              <input 
                type="text" 
                // required
                value={bankName}
                onChange={(e)=>{setBankName(e.target.value)}}
                /> 
          </div>
      
          <div className='input'>
              <label htmlFor="">Account Number : </label>
              <input 
                type="text" 
                // required
                value={accountNumber}
            onChange={(e)=>{setAccountNumber(e.target.value)}}
                /> 
          </div>

          <div className='input'>
              <label htmlFor="">Salary: </label>
              <input 
                type="number"
                // required 
                value={salary}
                onChange={(e)=>{setSalary(e.target.value)}}
                /> 
          </div>
      
          <div className='input'>
              <label htmlFor="">TIN: </label>
              <input 
                type="number" 
                value={tin}
                onChange={(e)=>{setTin(e.target.value)}}
                /> 
          </div>
      
          <div className='input'>
              <label htmlFor="">SSNIT: </label>
              <input 
                type="number" 
                value={snnitNumber}
                onChange={(e)=>{setSnnitNumber(e.target.value)}}
                /> 
          </div>
      </div>
      <h2>Identification and Contacts</h2>  
      <div className="id-contact-details">
      <div className='input'>
          <label htmlFor="">Nationality : </label>
          <input 
            type="text" 
            // required
            value={nationality}
            onChange={(e)=>{setNationality(e.target.value)}}
            /> 
          </div>
      <div className='input'>
          <label htmlFor="">National ID: </label>
          <select 
            id="mySelect" 
            // required
            value={nationalIdType}
            onChange={(e)=>{setNationalIdType(e.target.value)}}
            >
                <option value="" hidden disabled>Choose a card </option>
                <option value="GHCARD">Ghana Card</option>
                <option value="VOTER">Voter's ID</option>
                <option value="NHI">NHIS</option>
                <option value="DRIVER">Driver's License</option>
            </select>
          </div>

          <div className='input'>
          <label htmlFor="">ID Number: </label>
          <input 
            type="text" 
            // required
            placeholder='GHA-XXXX-XXXX-XXXX-X'
            value={nationalIdNumber}
            onChange={(e)=>{setNationalIdNumber(e.target.value)}}
            /> 
          </div>

          <div className='input'>
          <label htmlFor="">Address: </label>
          <input 
            type="text" 
            // required
            value={address}
            onChange={(e)=>{setAddress(e.target.value)}}
            /> 
          </div>
      </div>
      <div id="submit-reset-buttons">
          <button type='submit'>Update </button>
          <button >Reset Input</button>
          </div>
      </form>
    </div>
  </div>
</div>
    
  )
}

export default StaffUpdate