import React, { useState, useEffect, useContext } from 'react'
import './finance.css'
import { FinancialActions } from '../../Components/reusables'
import {SideMenu} from '../../Components/sideMenu'
import Header from '../../Components/header'
import { IoIosArrowDown } from 'react-icons/io';
import axios from 'axios';
import { UserInterfaceContext } from '../../Contexts/ui-context';
import { Loading } from '../../Components/reusables'

function FeeCollection() {
  const {Progress, AlertNotice, setLoading} = useContext(UserInterfaceContext)

  const [academicYear, setAcademicYear] = useState([])
  const [academicTerm, setAcademicTerm] = useState([])
  const [feesData, setfessData] = useState([])

  const [selectedAcademicYear, setSelectedAcademicYear] = useState('')
  const [selectedAcademicTerm, setSelectedAcademicTerm] = useState('')
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [classValue, setClassValue] = useState()
  const [AllStudentsData, setAllStudentData] = useState([])
  const [selectedStudent, setSelectedStudent] = useState('')

  function FetchAcademicYear(){
    Progress('30%')
    axios.get('https://new-softesc-backend.onrender.com/academicYear',)
    .then((response)=>{
      ////console.log(response.data)
      setAcademicYear(response.data)
      Progress('100%')
    })
    .catch((error)=>{
      //console.log(error)
      Progress('100%')
    })
  }

  function FetchAcademicTerm (){
    Progress('30%')
    axios.get('https://new-softesc-backend.onrender.com/academicTerm',{
        params:{
            academicYearName : selectedAcademicYear===''?'none':selectedAcademicYear,
        }
      })
      .then((response)=>{
        setAcademicTerm(response.data)
        ////console.log(response.data)
        Progress('100%')

      })
      .catch((error)=>{
        //console.log(error)
        Progress('0%')

      })
  }


  function FetchFinancialData (){
        setLoading(true)
        Progress('30%')
        axios.get('https://new-softesc-backend.onrender.com/finance/studentTerm', {
        params:{
          academicYear : selectedAcademicYear,
          academicTerm : selectedAcademicTerm,
          classValue : parseInt(classValue),
          refNumber : selectedStudent
          
        }},
        )
        .then((response)=>{
          Progress('100%')
          setfessData(response.data)
          setLoading(false)
        })
        .catch((error)=> {
          Progress('0%')
          //console.log('error from the retrieving')
        })
  }
        
  useEffect(()=>{
      FetchAcademicYear()
      FetchAcademicTerm();
      FetchFinancialData()

  },[selectedAcademicTerm,selectedAcademicYear, classValue, startDate, endDate,classValue, selectedStudent])

  const [sortingView, setSortingView] = useState(false)
  function toggleSortingView (){
    setSortingView(!sortingView)
  }


  //fetch students on change in stage/class
  useEffect(()=>{
   if(!classValue){
    return
   }
   else{
    axios.get('https://new-softesc-backend.onrender.com/students',{
      params : {
        stage : classValue
  
      }
    })
    .then((response)=>{
      setAllStudentData(response.data)
      ////console.log(response.data)
      
    })
    .catch((error)=>{
      //console.log(error)
      AlertNotice(error.response.data.error,)
    })
   }
  },[classValue])

  return (
    <div className="App">
        <SideMenu/>
        <div className="main-menu">
          <Header/>
          <div className='fee-collection content-loader'>
            <FinancialActions/>
            <div className="sorting sticky-left">
              <div className="sorting-header" onClick={toggleSortingView}>
              <h2>Sort By:</h2>
              <IoIosArrowDown id='sorting-down-arrow'/>
              </div>
                <div className='sorting-body' style={{display: sortingView?'none':'flex'}}>
                  
                <div className="input">
              <label htmlFor="">Academic Year: </label>
              <select 
                name="" 
                id=""
                value={selectedAcademicYear}
                onChange={(e)=>{setSelectedAcademicYear(e.target.value)}}

                >
                {/* <option value="" disabled hidden >select an academic year</option> */}
                <option value="" >All</option>
                {academicYear.map((year, index)=>(
                      <option key={index} value={year.academicYearName}>{year.academicYearName}</option>

                ))}            

              </select>
              

            </div>
            <div className="input">
              <label htmlFor="">Academic Term: </label>
              <select 
                name="" 
                id=""
                value={selectedAcademicTerm}
                onChange={(e)=>{setSelectedAcademicTerm(e.target.value)}}
                >
                <option value=""  >All</option>
                {academicTerm.map((term, index)=>(
                      <option key={index} value={term.academicTermName}>{term.academicTermName}</option>

                ))}
              </select>
              
              
            </div>



                    <div className="input">
                        <label htmlFor="">Class: </label>
                            <select 
                              required
                              value={classValue}
                              onChange={(e)=>{setClassValue(e.target.value)}}
                              >
                                <option value="">All </option>
                                <option value={-4}>Preschool - CRECHE</option>
                                <option value={-3}>Preschool - Nursery 1</option>
                                <option value={-2}>Preschool - Nursery 2</option>
                                <option value={-1}>Preschool - Kingdergarten 1</option>
                                <option value={0}>Preschool - Kingdergarten 2</option>
                                <option value={1}>Class 1</option>
                                <option value={2}>Class 2</option>
                                <option value={3}>Class 3</option>
                                <option value={4}>Class 4</option>
                                <option value={5}>Class 5</option>
                                <option value={6}>Class 6</option>
                                <option value={7}>Class 7 / JHS 1</option>
                                <option value={8}>Class 8 / JHS 2</option>
                                <option value={9}>Class 9 / JHS 3</option>
                            </select>
                    </div>

                    <div className="input">
                      <label htmlFor="" >Student: </label>
                      <select 
                        name="" 
                        id=""
                        value={selectedStudent}
                        onChange={(e)=>{setSelectedStudent(e.target.value)}}
                        >
                          <option value=""  >All</option>
                          {
                            AllStudentsData.length>0?AllStudentsData.map((student, index)=>(
                              <option key={index} value={student.refNumber}>{student.firstName + ' ' + student.middleName + ' ' + student.lastName}</option>
                            )):null
                          }
                        </select>
                    </div>
                </div>
              
            </div>
            <div className="userfeature">
              <b>Total Fetched : {feesData.length}</b>
            </div>
            <div className='container'>
            <table className='user-table'>
                  <Loading/>
                  <thead>
                    <tr>
                      <th >Year</th>
                      <th >Term</th>
                      <th >RefNumber</th>
                      <th id='user-name-head'>Name</th>
                      <th style={{color: ''}}>Paid (GHS)</th>  
                      <th style={{textAlign : 'center'}}>School Fees (GHS)</th>  
                      <th style={{color: '', textAlign: 'right'}}>Amount Owing (GHS)</th>  
                      <th>Description</th>
                      <th id='user-actions-head'>Status</th>
                      {/* <th id='user-actions-head'>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      feesData.length>0?(feesData.map((feesData, index)=>(
                        <tr key={index}>
                            <td>{feesData.academicYear}</td>
                            <td>{feesData.academicTerm}</td>
                            <td >{feesData.refNumber.id}</td>
                            <td id='user-name'>{feesData.refNumber.name}</td>
                            <td style={{textAlign : 'right', fontWeight : 'bolder'}}>{feesData.financialData.paidFees}</td> 
                            <td style={{textAlign : 'right',  fontWeight : 'bolder'}}>{feesData.financialData.totalFees}</td>
                            <td style={{textAlign : 'right', fontWeight : 'bolder'}}>{feesData.financialData.totalFees - feesData.financialData.paidFees}</td>
                          <td>{feesData.description}</td>
                          <td id='table-actions'>{(feesData.financialData.totalFees - feesData.financialData.paidFees)<=0?<b style={{color: 'lightgreen'}}>PAID</b>: <b style={{color: 'red'}}>OWING</b> }</td>
                          {/* <td id='table-actions'> */}
                            {/* <FaEdit/>  */}
                            {/* N/A */}
                            {/* </td> */}
                      </tr>
                      ))):
                      (<tr>
                        <td colSpan='10' style={{padding:'1rem', color:'red'}}>NO FEES DATA TO SHOW FOR THIS SORTING CRITERIA</td>
                      </tr>)
                    }
                    
                  </tbody>
            </table>
            </div>    
          </div>
        </div>
      </div>
    
  )
}

export default FeeCollection