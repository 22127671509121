import React, { useContext,useEffect,useState } from 'react'
import './all-notices.css'
import {SideMenu} from '../../Components/sideMenu'
import Header from '../../Components/header'
import { IoIosArrowDown } from 'react-icons/io';
import { Alert, Loading } from '../../Components/reusables';
import { UserInterfaceContext } from '../../Contexts/ui-context'
import axios from 'axios';
import { FaEye, FaEdit, FaTrash, FaUser } from 'react-icons/fa';


function AllNotices() {
  const {setPageName, Progress, AlertNotice, setLoading} = useContext(UserInterfaceContext)

  setPageName('NOTICES AND MESSAGES')

  const [sortingView, setSortingView] = useState(false)
  function toggleSortingView (){
    setSortingView(!sortingView)
  }
  
  const [pastMessages, setPastMessages] = useState([])
  const [receiver, setReceiver] = useState('')
  const [sender] = useState('Admin')
  const [title, setTitle] = useState('')
  const [channel, setChannel] = useState('')
  const [messageBody, setMessageBody] = useState()
  const [pastMessageReload, setPastMessageReload] = useState(false)
  const [stage, setStage] = useState('')
  const [noticePageContent, setNoticePageContent] = useState('ALL-MSG')
  // const [toggle]
  const messageData = {
    receiver : receiver,
    sender : sender,
    title : title,
    channel: channel,
    messageBody : messageBody,
  }

  function ResetMessageInputs (){
    setReceiver('')
    setTitle('')
    setChannel('')
    setMessageBody('')

  }


  function handleMessageSubmit (e){
    e.preventDefault()
    Progress('30%')
    axios.post('https://new-softesc-backend.onrender.com/notices', messageData)
    .then((response)=>{
      console.log(response)
      AlertNotice(response.data.message, true)
      Progress("100%")
      // ResetMessageInputs()
      setPastMessages(response.data.notice)

    })
    .catch((error)=>{
      AlertNotice('Unable to send Notice', false)
      console.log('something went wrong', error)
      Progress('100%')
    })
  }


  //call for the past messages

  useEffect(()=>{
    Progress('30%')
    setLoading(true)
    axios.get('https://new-softesc-backend.onrender.com/notices')
    .then((response)=>{
      Progress('100%')
      setLoading(false)
      setPastMessages(response.data)
    })
    .catch((error)=>{
      Progress('0%')
      //console.log('unable to retrieve past messages', error)
    })

  },[pastMessageReload])



  return (
    <div className="App">
        <SideMenu/>
        <Alert/>
        <div className="main-menu">
          <Header/>
         
          <div className='notices content-loader'>
          <div className="action-buttons">
            <button onClick={()=>{setNoticePageContent('ALL-MSG')}} style={noticePageContent==='ALL-MSG'?{backgroundColor: '#f47c2c', color: '#031c3b' }:{backgroundColor: 'transparent', color: 'white'}}>All Messages</button>
            <button onClick={()=>{setNoticePageContent('SND-MSG')}} style={noticePageContent==='SND-MSG'?{backgroundColor: '#f47c2c', color: '#031c3b' }:{backgroundColor: 'transparent', color: 'white'}}>Send Message</button>
            <button onClick={()=>{setNoticePageContent('BUY-SMS-CRDT')}} style={noticePageContent==='BUY-SMS-CRDT'?{backgroundColor: '#f47c2c', color: '#031c3b' }:{backgroundColor: 'transparent', color: 'white'}}>Buy SMS Credit</button>
          </div>
            <div className="sorting" style={{display: 'flex', flexDirection : 'row', marginBottom: '0rem'}}>
              SMS Credit Balance : <b>20.00</b>
            </div>

           {
            noticePageContent==='ALL-MSG'?

                <div className="container">
                  <table>
                    <Loading/>
                  <thead>
                    <tr>
                      <th id='serial-num-head'>Message ID</th>
                      <th>Sender</th>
                      <th id='user-name-head'>Reciever</th>
                      <th>Title</th>
                      <th>Message </th>
                      <th>dateAdded</th>
                      <th>Channel</th>
                      {/* <th>Status</th> */}
                      <th id='user-actions-head'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pastMessages.length>0?(pastMessages.map((pastMessages, index)=>(
                        <tr key={index}>
                        <td >{pastMessages._id}</td>
                        <td>{pastMessages.sender}</td>
                        <td>{pastMessages.receiver.toUpperCase()}</td>
                        <td>{pastMessages.title}</td>
                        <td>{pastMessages.messageBody}</td>
                        <td>{pastMessages.dateAdded}</td>
                        {/* <td>{pastMessages.messageApiBody.}</td> */}
                        <td>{pastMessages.channel}</td>
                        <td id='table-actions'>
                          <div>
                            <FaTrash id='action-delete' onClick={()=>{    }}/>
                          </div>
                            </td>
                      </tr>
                    ))):
                    (<tr>
                      <td colSpan='22' style={{padding:'1rem', color:'red'}}>NO MESSAGES YET</td>
                    </tr>)
                    
                            
                    }
                        
                            
                            
                  </tbody>
                </table>
                  </div>
                     :null
           }








           {
            noticePageContent==='SND-MSG'?
            
          <div className="sorting">
             
          {/* <div className="sorting-header" onClick={toggleSortingView}>
          <h2>Send a Note / Message</h2>
          <IoIosArrowDown id='sorting-down-arrow'/>

          </div> */}
          <form onSubmit={handleMessageSubmit}>
          <div className="sorting-body"style={{display: sortingView?'none':'flex'}}>
          <div className='input'>
          <label htmlFor="">Receiver: </label>
          <select 
          name="" 
          required
          id=""
          value={receiver}
          onChange={(e)=>{setReceiver(e.target.value)}}
          >
            <option value="" hidden disabled>Select Reciever Group</option>
            <option value="Parents">Parents</option>
            <option value="Staff">General Staff</option>
          </select>
          </div>
          <div className="input">
        <label htmlFor="">Class: </label>
        <select 
          value={stage}
          onChange={(e)=>{setStage(e.target.value)}}
        >
            <option value="">All </option>
            <option value={-4}>Preschool - Creche</option>
            <option value={-3}>Preschool - Nursery 1</option>
            <option value={-2}>Preschool - Nursery 2</option>
            <option value={-1}>Preschool - Kingdergarten 1</option>
            <option value={0}>Preschool - Kingdergarten 2</option>
            <option value={1}>Class 1</option>
            <option value={2}>Class 2</option>
            <option value={3}>Class 3</option>
            <option value={4}>Class 4</option>
            <option value={5}>Class 5</option>
            <option value={6}>Class 6</option>
            <option value={7}>Class 7 / JHS 1</option>
            <option value={8}>Class 8 / JHS 2</option>
            <option value={9}>Class 9 / JHS 3</option>
        </select>


      </div>
      

          <div className='input'>
          <label htmlFor="">Channel: </label>
          <select name="" id=""
            value={channel}
            required
            onChange={(e)=>{setChannel(e.target.value)}}
          >
            <option value="">Select Channel</option>
            <option value="sms">SMS</option>
            <option value="email">EMAIL</option>
          </select>

          </div>

          <div className='input'>
          <label htmlFor="">Title of Message: </label>
          <input 
            type="text"
            required
            placeholder='Title of the text you want to send' 
            value={title}
            onChange={(e)=>{setTitle(e.target.value)}}
          /> 

          </div>

          <div className='notice-textarea'>
          <div className="input ">
          <label htmlFor="">Message: </label>
          <textarea 
          name="" 
          required
          id="" 
          cols="30" 
          rows="10" 
          placeholder='Enter your message here'
          value={messageBody}
          onChange={(e) => {setMessageBody(e.target.value)}}
          ></textarea> 
          </div>
          <br />
          <div id='submit-reset-buttons' >
            <button type='submit'>Send Message</button>
            <button type='button' onClick={ResetMessageInputs}>Reset Inputs</button>
          </div>
          </div>
          
          </div>
          </form>
         
          
          
        </div>
            :null
           }











           {
            noticePageContent==='BUY-SMS-CRDT'?
              <div className="sorting">
                  <form action="" >
                    <div className="sorting-body">
                    
                    <div className="input">
                      <label htmlFor="">Network</label>
                      <select name="" id="">
                      <option value="" hidden disabled>Choose your Network</option>
                      <option value="">MTN</option>
                      <option value="">TELECEL</option>
                      <option value="">AIRTELTIGO</option>
                     
                    </select>
                    </div>


                    <div className="input">
                    <label htmlFor="">Phone Number</label>
                    <input type="number" placeholder='Enter phone here'/>
                    </div>

                    <div className="input">
                    <label htmlFor="">Enter Amount</label>
                    <input type="number" placeholder='Enter amount here'/>
                    </div>
                    </div>
                   
                  </form>
                  <div id="submit-reset-buttons">
                    <button>Buy Credit</button>
                    <button>Reset Inputs</button>
                  </div>
              </div>
                          :null
           }


            
            
            </div>

        </div>
      </div>
    
  ) 
}

export default AllNotices